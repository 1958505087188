.logo {
  width: 18px;
  height: 18px;
  background-image: url("../../../assets/ducount.svg");
  background-repeat: no-repeat;
}

.leftNav {
  background-color: var(--color-blue-dark);
}

.navMenu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.navMenu button {
  background: none;
  border: none;
  outline: none;
  text-align: start;

  display: flex;
  width: 100%;

  padding: 8px 12px;

  font-size: 16px;

  white-space: nowrap;

  color: var(--color-blue-lighter);
}

@media only screen and (min-width: 601px) {
  .logo {
    margin: 12px 0;

    transition: margin 200ms ease-in-out;
  }

  .productName {
    display: none;
  }

  .leftNavWrapper {
    flex: 0 0 42px;
    transition: flex-basis 200ms ease-in-out;
  }

  .leftNavWrapper:hover {
    flex-basis: 200px;
  }

  .leftNav {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 10;
    width: 42px;
    top: 0;
    bottom: 0;
    left: 0;

    transition: width 200ms ease-in-out;

    overflow: hidden;
  }

  .leftNav:hover .logo {
    margin-left: 79px;
  }

  .leftNav:hover {
    width: 200px;
  }

  .navMenu {
    flex: 1 0;
    display: flex;
    flex-direction: column;
  }

  .navText {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
  }

  .logoWrapper {
    box-sizing: border-box;
    height: 64px;
    padding: 12px;
  }

  .spacerEntry {
    flex: 1 1;
  }

  .expandMenuButton {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .logoWrapper {
    box-sizing: border-box;
    height: 34px;
    padding: 8px 12px;

    display: flex;
  }

  .productName {
    margin: 0;
    padding: 0 8px;
    color: var(--color-blue-lighter);
    font-size: 16px;
  }

  .navMenu {
    display: none;
    position: absolute;
    z-index: 500;
    background-color: var(--color-blue-dark);

    width: 100%;
  }

  .expanded .navMenu {
    display: block;
  }

  .expandMenuButton {
    display: block;
    position: absolute;
    top: 0;
    right: 0;

    appearance: none;
    outline: none;
    border: 0;

    background: none;
    padding: 8px;
  }

  .expandMenuButton > svg {
    display: block;
    fill: var(--color-blue-lighter);
  }
}

.navIcon {
  display: block;
  flex: 0 0;
}

.navText {
  display: block;
  padding: 0 8px;
  flex: 0 0;
}

.userButton {
}

.userIconWrapper {
  display: inline-block;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  border: 1px solid var(--color-blue-lighter);
  border-radius: 9999px;
  overflow: hidden;
  position: relative;
}

.userIconWrapper svg {
  fill: var(--color-blue-lighter);
  position: absolute;
  bottom: 0;
  margin-left: -7px;
  left: 50%;
}

.leftNav:hover .navText {
  opacity: 1;
}

.navMenu button:hover {
  background-color: var(--color-blue-medium);
}
