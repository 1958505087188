.transactionBox {
  border: 1px solid #eee;
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 4px;
}

.hoverTransaction {
  background-color: #ddd;
}

.addTransactionBox {
  height: 50px;
  text-align: center;
  line-height: 50px;
}

.transactionTop {
  display: flex;
}

.splitList {
  padding: 0;
  list-style: none;
}

.splitRow {
  display: flex;
}

.splitRowGripCell {
  flex: 0 0 auto;
  width: 10px;
  cursor: grab;
  display: flex;
  align-items: center;
}

.splitRowGripCell:active {
  cursor: grabbing;
}

.splitRowAccountCell {
  padding: 2px;
  flex: 1 1 auto;
}

.splitRowAmountCell {
  padding: 2px;
  width: 40px;
  flex: 0 1 auto;
}

.splitRowButtonCell {
  padding: 1px;
  width: 16px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
}

.dateEditor {
  position: absolute;
  right: 0;
  z-index: 999;
  width: 240px;
  background-color: white;
  display: flex;
  padding: 8px;
  border: 1px solid #ccc;
}
