.sidebar {
  position: sticky;
  top: 0;
  height: 100vh;
  box-sizing: border-box;
  overflow: auto;
  padding: 16px;
}

.txCreatorAmountCell {
}
