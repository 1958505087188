.topbar {
  width: 100%;
  background-color: var(--color-yellow-lighter);
  border-bottom: 1px solid var(--color-yellow-light);
  color: var(--color-yellow-light);
  text-align: center;
  padding: 4px;
}

.topbar button {
  appearance: none;
  outline: none;
  border: none;
  background: none;

  cursor: pointer;

  font-size: 1em;
}
