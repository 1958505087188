body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 12px;

  background-color: #fafbfe;

  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  font-size: inherit;
}

#root {
  display: flex;
}

@media only screen and (max-width: 600px) {
  #root {
    flex-direction: column;
  }
}

:root {
  --color-blue-dark: #0f2042;
  --color-blue-darker: #030c1f;
  --color-blue-medium: #273c65;
  --color-blue-light: #495e87;
  --color-blue-lighter: #8395b7;
  --color-blue-lightest: #bbcef5;

  --color-red-medium: #832a4c;

  --color-purple-medium: #452666;

  --color-yellow-medium: #979030;
  --color-yellow-light: #cac363;
  --color-yellow-lighter: #fffaae;
}
