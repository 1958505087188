.caret {
  transition: transform 0.3s;
}

.caretOpen {
  transform: rotate(90deg);
}

.caretClosed {
  transform: rotate(0);
}

.listRoot {
  font-size: 14px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.list {
  list-style: none;
  padding-left: 16px;
}

.value {
  float: right;
}
