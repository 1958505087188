.wrapper {
  padding: 12px 12px;
}

.title {
  text-align: center;
}

.wrapper :global(.rv-treemap__leaf):hover {
  box-shadow: inset 0 0 0 2px black;
  border-color: black;
}
