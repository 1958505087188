.container {
  display: inline-block;
}

.suggestionsContainerOpen {
  position: absolute;
  max-height: 400px;
  overflow: auto;
  background-color: white;
  border: 1px solid #888;
  box-shadow: 1px 1px 3px 1px rgba(222, 222, 222, 0.75);
  z-index: 1000;
}

.suggestionHighlighted {
  background-color: #ccc;
}

.suggestionsList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.suggestion {
  padding: 4px;
}

.input {
  width: 100%;
  border: 1px solid #efefef;
  box-sizing: border-box;
}
