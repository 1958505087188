.accounts {
  flex: 1 1;
  padding: 16px;
}

.searchBar {
  background-color: white;

  position: sticky;
  top: 0;
  padding: 8px 0;
}

.settings {
  padding: 16px;
}

button.link {
  color: var(--color-blue-medium);
}

button.link:hover {
  text-decoration: underline;
}

.accountSelector {
  min-width: 80px;
}

.actions {
  list-style: none;
  padding-left: 4px;
}
