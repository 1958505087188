.mainContent {
  display: flex;
}

.entries {
  flex: 1 1;
}

.spacer {
  flex: 0 0 16px;
}

.sidebar {
  flex: 0 0 300px;
}
