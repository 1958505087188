.input {
  text-align: right;
}

.input::-webkit-inner-spin-button,
.input::-webkit-outer-spin-button {
  appearance: none;
}

.increaseInput {
  background-color: #f4fff0;
}

.decreaseInput {
  background-color: #fff0f5;
}
