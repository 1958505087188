.topRow {
  display: flex;
}

.amountCell {
  width: 60px;
}

.amountCell > input {
  width: 60px;
}

.deleteButton {
  appearance: none;
  border: 0;
  background: none;
  cursor: pointer;
}

.deleteButton:disabled > svg {
  fill: #eee;
}
.deleteButton > svg {
  fill: #aaa;
}

.quickCalculatorMenu {
  position: absolute;

  list-style: none;

  padding: 4px 0;
  margin: 0;

  right: 0;

  z-index: 900;

  display: flex;
}
