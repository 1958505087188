.sectionWrapper {
}

.sectionWrapperTitle {
  color: #888;
  margin: 0;
  padding: 8px;
  font-size: 16px;
  border-bottom: 1px solid #e3e3e3;
}

.sectionWrapperMain {
  padding: 8px;
}
