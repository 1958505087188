.filterList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.filterList > li {
  display: flex;
  align-items: center;
}

.filterList > li:nth-child(even) {
  background-color: #f3f3f3;
}

.deleteClauseButton {
  fill: #aaa;
}

.addClauseButton {
  fill: #aaa;
}

.addClauseCell {
  text-align: center;
  width: 50px;
}
