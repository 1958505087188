.accountList {
  list-style: none;
  padding: 0;
}

.caret {
  padding: 0;
}

.rootAccountList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.accountLine {
  font-size: 12px;
  border-left: 2px solid rgba(255, 255, 255, 0);
  padding: 0;

  display: flex;
}

.accountLine:hover {
  background-color: #fafafa;
}

.selectedAccountLine {
  border-left: 2px solid var(--color-blue-lighter);
}

.accountName {
  display: block;
  flex: 1 1;
  width: 0;
  padding: 4px 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 4px;
  overflow: hidden;
}

.balance {
  display: block;
  color: #666;
}

.accountLine:hover .balance {
  display: none;
}

button.onlyButton {
  display: none;
  float: right;
  color: #666;
  font-size: 0.8em;
  line-height: 14px;
}

.onlyButton:hover {
  text-decoration: underline;
}

.accountLine:hover .onlyButton {
  display: inline-block;
}
