.accountSelector {
  background-color: white;
  border: 1px solid #eee;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 16px;
  text-align: center;
  margin-top: 16px;
}

.accountSelectorOverlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
