.wrapper {
  padding: 8px;
}

.ledgerTable {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  border-collapse: collapse;
}

.evenRow {
  background-color: #e9ecf0;
}

.expandedRow {
  background-color: var(--color-blue-lightest);
}

.expandRow {
  background-color: var(--color-yellow-lighter);
}

.ledgerTable > tbody > tr {
  transition: background-color 0.16s ease;
}

.ledgerTable > thead > tr > th,
.ledgerTable > tbody > tr > td,
.ledgerTable > tfoot > tr > td {
  padding: 4px;
  border: 1px solid var(--color-blue-lighter);
}

.ledgerTable > tfoot {
  background-color: var(--color-blue-lightest);
  font-weight: 500;
}

.checkboxCell {
  width: 20px;
}

.dateCell {
  width: 60px;
}

.accountCell {
  width: 120px;
}

.amountCell {
  width: 80px;
}

td.amountCell {
  text-align: right;
}

.expandCell {
  width: 16px;
}

.menuCell {
  width: 16px;
}

.menuCell button {
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.menuCell svg {
  display: block;
}

/* Add row */
.quickAddRow .dateCell,
.quickAddRow .accountCell,
.quickAddRow .amountCell,
.quickAddRow .amountCell {
}

.dateInput,
.amountInput,
.memoInput,
.accountInput {
  box-sizing: border-box;
  border: 0;
  border-bottom: 1px solid #ddd;
}

.dateInput {
  width: 120px;
}

.amountInput {
  width: 80px;
  text-align: end;
}

.memoInput {
  width: 100%;
}

.accountInputDisplay {
  padding: 0;
  width: 100%;
  text-align: start;
}

.quickAddButton:focus {
  outline: 1px dotted #888;
}

.inputError {
  border: 1px solid red;
}

/* Pager, etc */
.bottom {
  display: flex;
  align-items: center;
}

.pager {
  flex: 1 0;
}
