.wrapper {
  border: 1px solid #eee;
}

.dropdownWrapper {
  display: inline;
}

.picker {
  position: absolute;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  z-index: 999;
}

.rangeType {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 4px;
  border-bottom: 1px solid var(--color-blue-lightest);
  font-size: 16px;
}

.rangeTypeItem {
}

.rangeTypeItem > button,
.subyearYearButton {
  outline: none;
  appearance: none;
  border: 0;
  background: none;
  cursor: pointer;
}

.rangeTypeItem > button:disabled,
.subyearYearButton:disabled {
  cursor: initial;
}

.rangeTypeItem > button {
  font-weight: bold;
  text-transform: uppercase;
  color: var(--color-blue-lighter);
}

.rangeTypeItem > button:hover,
.rangeTypeItem > button:disabled {
  color: var(--color-blue-dark);
}

.yearList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 32px 32px 32px 32px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.yearListItem {
  justify-self: center;
  align-self: center;
}

.yearButton {
  color: var(--color-blue-dark);
  font-size: 16px;
}

.yearOther {
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  width: 100%;
  outline: none;
  background: none;
  border: none;
  border-bottom: 1px solid #ccc;
}

.subyearPicker {
  display: flex;
}

.subyearYearList {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: var(--color-blue-lightest);
  width: 48px;
}

.subyearYearListItem {
  margin: 8px 0;
}

.subyearYearButton {
  color: var(--color-blue-light);
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  text-align: center;
}

.subyearYearListOther {
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  width: 100%;
  outline: none;
  background: none;
  border: none;
  border-bottom: 1px solid #ccc;
}

.subyearYearButton:hover,
.subyearYearButton:disabled {
  color: var(--color-blue-dark);
}

.quarterList {
  flex: 1 1;
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.quarterListItem {
  justify-self: center;
  align-self: center;
  font-size: 24px;
}

.quarterListItem > button {
  font-weight: bolder;
}

.monthList {
  flex: 1 1;
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.monthListItem {
  justify-self: center;
  align-self: center;
}

.calendar {
  border: 0;
}

.prevNextButton {
  padding: 4px;
}
