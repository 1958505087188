.account {
  position: relative;

  margin: 16px 0;
  padding: 8px;
}

.accountUnselected {
  filter: blur(2px);
  pointer-events: none;

  min-height: 180px;
}

.actionList {
  margin: 0;
  padding: 0;
  list-style: none;
}

.actionList li {
  display: inline-block;
  margin-right: 8px;
}
.actions {
  position: sticky;
  top: 0;
  z-index: 1;
  margin: 0;
  padding: 8px;
}
