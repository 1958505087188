.pager {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 4px 0;
}

.pager li {
  margin: 0 2px;
  padding: 4px 2px;
  border: 1px solid var(--color-blue-lighter);
  color: var(--color-blue-lighter);
  text-align: center;
  min-width: 16px;
}

.pager li button {
  appearance: none;
  outline: none;
  display: inline;
  border: none;
  margin: 0;
  padding: 0;
  font-size: 1em;
  color: var(--color-blue-dark);
  cursor: pointer;
}

.pager li button:hover {
  text-decoration: underline;
}

.pager li.current {
  background-color: var(--color-blue-lighter);
  color: white;
}

.richPager {
  display: flex;
  align-items: center;
}
