.overlay {
  z-index: 100;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(255, 255, 255, 0.75);
}

.content {
  position: absolute;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  border-radius: 4px;
  outline: none;
  padding: 20px;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.3);
}

.content.small {
  width: 300px;
  left: calc(50% - 150px);
  top: 40px;
}

.content.large {
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
}
