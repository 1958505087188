.wrapper {
  flex: 1 1;
}

.section {
  padding: 16px;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 16px;
}

.title {
  margin: 0;
  font-size: 14px;
  text-transform: uppercase;
  color: #555;
}

.list {
  list-style: none;
  padding: 0;
}

.listItem {
  padding: 4px 0;
}

.criteriaLabel {
  display: inline-block;
  min-width: 120px;
}

.actionLabel {
  display: inline-block;
  min-width: 180px;
}

.previewWrapper {
  display: flex;
}

.previewRow:hover {
  background-color: #f0f0f0;
}

.previewTable {
  border-spacing: 0;
}

.previewRow td {
  border: 0;
  padding: 4px;
}

.entriesPreviewWrapper {
  flex: 1 1;
}

.actionPreviewWrapper {
  flex: 0 1 320px;
}

.entryDateCell {
  text-align: right;
  width: 120px;
}

.entryAmountCell {
  text-align: right;
  width: 120px;
}

.entrySpacerCell {
  width: 40px;
}

.actionPreview {
  background-color: #f0f0f0;
  padding: 8px;
}

.actionPreviewTransactionMemo {
  margin: 0;
}
