.input {
  border: 1px solid #aaa;
  outline: none;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-sizing: border-box;
  height: 18px;
}

.input:disabled {
  background-color: #eee;
}

.input:focus-within {
  border: 1px solid var(--color-blue-light);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 3px rgba(21, 93, 151, 0.6);
}

.fullWidth {
  width: 100%;
}
