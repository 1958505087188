.noData {
  width: 100%;
  display: flex;

  justify-content: center;
  align-items: center;
}

.charts {
  padding: 8px;
}

.chartsLegend {
  font-weight: 700;
}

.defaultCharts {
  display: inline;
  list-style: none;
}

.defaultCharts li {
  display: inline;
  padding: 0;
}

.defaultCharts li:not(:first-child)::before {
  content: "|";
}

.chartButton {
  padding: 0 4px;
}

.chartButton:hover {
  text-decoration: underline;
}
