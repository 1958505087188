.settingsBlock {
  padding: 16px;
  margin-bottom: 16px;
}

.settingsBlock h2 {
  text-transform: uppercase;
  color: var(--color-blue-medium);
  margin: 0 0 8px 0;
  font-size: 16px;
}
