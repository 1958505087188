.wrapper {
  position: relative;
}

.menu {
  position: absolute;
  list-style: none;

  padding: 4px 0;
  margin: 0;

  right: 0;

  z-index: 900;

  background-color: white;

  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
}

.menuitem {
  color: var(--color-blue-darker);
  white-space: nowrap;
  padding: 4px;
  cursor: pointer;
}

.menuitem:hover {
  background-color: var(--color-blue-medium);
  color: white;
}
