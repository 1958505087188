.container {
  position: fixed;
  bottom: 0;
  right: 0;
}

.wrapper.hidden {
  right: -120px;
}

.wrapper {
  position: relative;
  width: 140px;
  height: 100%;
  right: -0;
  transition: right 0.3s ease-in-out;
}

.main {
  position: absolute;
  bottom: 24px;
  left: 24px;
  width: 120px;
  border-radius: 4px;
  box-shadow: 0 0 3px black;
  background-color: white;
}

.add {
  position: absolute;
  border-radius: 4px;
  box-shadow: 0 0 3px black;

  left: 4px;
  right: 8px;
  bottom: 32px;
  height: 40px;
  background-color: white;
}

.addButton {
  font-weight: 900;
  color: #888;
  line-height: 40px;
  width: 20px;
  text-align: center;
}

.menuItems {
  list-style: none;
  margin: 0;
  padding: 8px 0px;
}

.menuItems button {
  width: 100%;
  font-size: 14px;
  text-align: left;
  appearance: none;
  border: 0;
  background: none;
  cursor: pointer;
  outline: none;
  padding: 4px 4px;
}

.menuItems li:hover {
  background-color: #ddd;
}
