.wrapper {
  padding: 8px;
  margin-bottom: 16px;
}

.navButton {
  fill: #aaa;
}

.navButton:disabled {
  fill: #ddd;
}

.filterButton {
  fill: #ddd;
}

.filterButton[aria-expanded="true"] {
  fill: #aaa;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
