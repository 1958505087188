.uploadPage h2 {
  text-transform: uppercase;
  font-size: 16px;
  color: var(--color-blue-light);
  margin: 16px 0 8px 0;
}
.uploadPage h2:first-child {
  margin-top: 0;
}

.dropTarget {
  text-align: center;
  padding: 40px 0;
}

.dropTargetOver {
  background-color: var(--color-blue-lighter);
}

.uploadButton {
  display: inline-block;
}

.uploadButton input {
  display: none;
}
