.styleless {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: inherit;
  padding: 0;
  text-align: start;
}

.styleless:disabled {
  cursor: auto;
}

.link {
  display: inline;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: inherit;
  color: var(--color-blue-medium);
  padding: 0;
}

.link:hover {
  text-decoration: underline;
}

.button {
  background-color: white;
  border: 0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
  padding: 4px 12px;
  outline: none;
  cursor: pointer;
}

.button:active {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  transform: translateX(1px) translateY(1px);
}

.segmentWrapper {
  list-style: none;
  padding: 0;
  margin: 0;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}

.segmentButtonWrapper:first-child {
  margin-left: unset;
}
.segmentButtonWrapper {
  display: inline-block;
  margin-left: -1px;
}

.segmentButton {
  border: none;
  margin: 0;
  padding: 4px 12px;
  outline: none;
  cursor: pointer;
  background-color: white;
}

.buttonPurple {
  background-color: var(--color-purple-medium);
  color: white;
}

.buttonPurple:disabled {
  color: #888;
}

.buttonBlue {
  background-color: var(--color-blue-medium);
  color: white;
}

.buttonBlue:disabled {
  color: #888;
}

.buttonRed {
  background-color: var(--color-red-medium);
  color: white;
}

.buttonRed:disabled {
  color: #888;
}

.buttonPurpleOutline {
  border: 1px solid var(--color-purple-medium);
  padding: 3px 11px;
  color: var(--color-purple-medium);
}

.buttonBlueOutline {
  border: 1px solid var(--color-blue-lighter);
  padding: 3px 11px;
  color: var(--color-blue-medium);
}
