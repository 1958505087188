.unreconciled {
  display: flex;
}

.spacer {
  width: 30px;
}

.unreconciledSplits,
.unreconciledEntries {
  flex: 1 1;
}

.selector {
  width: 20px;
}

.reconcilationTitle {
  display: flex;
  align-items: center;
}

.reconcilationTitle > h3 {
  flex: 1 0;
}

.reconcilationTitle > button {
  display: none;
}

.reconcilation:hover .reconcilationTitle > button {
  display: unset;
}

.reconcilationTable,
.unreconciledTable {
  width: 100%;
  table-layout: fixed;
}

.reconcilationTable td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dateCell {
  width: 70px;
  text-align: right;
}

.amountCell {
  width: 70px;
  text-align: right;
}

.reconcilationHelp {
  height: 30px;
  line-height: 30px;
  color: #888;
  text-align: center;
}
