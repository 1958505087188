.accountHeader {
  display: flex;
}

.accountName {
  flex: 1 1;
}

.accountProvider {
  color: #aaa;
}

.accountSelectorInput {
  display: inline-block;
  text-align: start;
}

.entryTable {
  width: 100%;
  border-collapse: collapse;
}

.entryTable tr {
  border: 1px solid #ddd;
}

.entryTable td {
  padding: 4px;
}

.entryTable tbody tr:nth-child(even) {
  background-color: #e9ecf0;
}

.checkboxCell {
  width: 16px;
}

.amountCell {
  width: 60px;
  text-align: right;
}

.otherAccountSelectorCell {
  width: 120px;
}

.dateCell {
  width: 80px;
}

.otherAccountSelectorInner {
  display: flex;
  align-items: center;
}

.clearTransactionButton {
  display: inline-block;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background: none;
  border: none;
  padding: 0;
}

.clearTransactionButton svg {
  fill: #ddd;
}

.mismatchBalance {
  background-color: #fff3cd;
  border-color: #ffeeba;
  border-radius: 8px;
  padding: 16px;
  margin-top: 8px;
}

.mismatchBalanceTitle {
  margin: 0 0 8px;
}
