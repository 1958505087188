@media only screen and (min-width: 601px) {
  .mainContent {
    flex: 0 0 calc(100% - 42px);
  }
}

.footer {
  height: 16px;
  padding: 8px;
  color: #ccc;
  text-align: right;
}

.checkboxSelection {
  border: 1px solid blue;
  background-color: rgba(0, 0, 255, 0.1);
  position: absolute;
  z-index: 1000;
  pointer-events: none;
}
