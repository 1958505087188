.main {
  display: flex;
}

.accounts {
  width: 260px;
}

@media only screen and (min-width: 1440px) {
  .accounts {
    width: 300px;
  }
}

.accountsBlock {
  margin-right: 16px;
  padding: 16px;
}

.display {
  flex: 1;
  padding: 0;
}

@media only screen and (max-width: 600px) {
  .main {
    flex-direction: column;
  }

  .accounts {
    width: 100%;
    margin-bottom: 16px;
  }

  .accountsBlock {
    margin: 0;
  }
}
