.main {
  display: flex;
}

.mainLeft {
  flex-grow: 1;
  flex-shrink: 1;
}

.mainRight {
  margin-left: 16px;
  position: relative;
  padding: 16px;
}

.actionsBlock {
  position: sticky;
  top: 0;
  padding: 8px;
  margin-bottom: 16px;
}

.actionsBlock > button {
  margin-right: 8px;
}

.transactionsBlock {
  padding: 8px;
}

.addTransactionClose {
  position: absolute;
  right: 16px;
  top: 16px;
}
